/* eslint-disable */
import React, { PureComponent } from 'react';
import { Layout } from 'containers';
import { Banner } from 'components';
import { Link } from 'gatsby';
class LightsCameraAction extends PureComponent {
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://starling.crowdriff.com/js/crowdriff.js";
    script.id = "cr-init__1648bd2e03653ee1";
    script.async = true;
    document.querySelector(".gallery").appendChild(script);
  }

  render() {
    return (
      <Layout page="lights-camera-action">
        <Banner backgroundImage="/images/lights_camera_action_landing_banner.jpg">
          <h1 className="c-heading c-heading--h1 lights-camera-action-heading">Lights! Camera! Action!</h1>
        </Banner>
        <section className="l-section">
          <div className="l-container l-container--small u-text--center">
            <h2 className="c-heading c-heading--h2">We're preparing for the launch of our biggest Kiwi summer yet.</h2>
            <p>
              We’ll highlight the best New Zealand has to offer - amazing weather, gorgeous night skies, a
              Kiwi Christmas, astonishing landscapes and epic adventures. Our tours have everything you need (plus
              more!), to make the best of your time in Aotearoa New Zealand. We'll take you to the most iconic
              destinations both on the North and South Island, while catering to your interests. If you're an adrenaline
              junky, choose skydiving, bungy jumping, horse riding, or quad biking. But if nature is more your vibe, why
              not pick a Milford Sound Cruise, a hike through the Tongariro Crossing or stargazing in spectacular
              glowworm caves!
            </p>
            <p>
              So if you are looking for the summer trip of a lifetime with amazing adventures, great people and new
              scenery, then you have found it! Join us on one of our summer tours to create life-long memories,
              meaningful connections and discover extraordinary new places.
            </p>
            <h2 className="c-heading c-heading--h2 mt-4">The winner of the photo contest for best picture is</h2>
            <h2 className="c-heading c-heading--h2" style={{"textDecoration": "underline", "textTransform": "capitalize"}}>Olivia from Canada</h2>
            <img className="mt-2" src="https://hakatours-staging.imgix.net/5d3e2fde6dd35b000112e018/Olivia_Nowek-Owned-2734-wl-04be2d96-24b3-477e-8b9e-59fc1df1f254-large_(1)_original.jpg" />
            <h2 className="c-heading c-heading--h2 mt-4">The winner of the voting contest is</h2>
            <h2 className="c-heading c-heading--h2"><span style={{"textDecoration": "underline", "textTransform": "capitalize"}}>Jenna</span> <span style={{"textTransform": "lowercase"}}>and</span> <span style={{"textDecoration": "underline", "textTransform": "capitalize"}}>Cara</span></h2>
            <h2 className="c-heading c-heading--h2 mt-4">Congratulations to both of you!</h2>
            <p>
              And as a thank you for participating in our contest, everyone who has submitted their picture or voted, has access to a 10% discount* on their next tour with us.
              Use the promocode <b>Lights10</b> at checkout to benefit from this promotion.
            </p>
            <h2 className="c-heading c-heading--h2 mt-4">View the top 20 photo submissions:</h2>
          </div>
          <div className="gallery mt-2"></div>
          <div className="l-container l-container--small u-text--center">
            <h4 className="l-alt-panels__item-title c-heading c-heading--h4 mt-4">Terms and conditions</h4>
            <p className="u-text--center">
              <i>
                Valid on new bookings only made from 25Nov – 30March 2023. Offer valid on any Haka Tours for travel 01Feb2023 – 31Dec 2023.
                Departure date subject to availability at time of booking. Under no circumstances will the discounts be applied to existing bookings.
                If not otherwise specified against the individual promotion, full payment is required at least 60 days prior to travel.
                The discounts cannot be combined or used in conjunction with any other offers. Discounts are subject to availability;
                may be withdrawn at any point without notice. Does not apply to pre- or post-tour accommodation, insurance,
                international airfare not specified in the itinerary, upgrades, add-ons, equipment rental, transfers, theme packs,
                or other in-country or on-board services. For full general T&Cs visit.
                <Link to='/booking-conditions/'>Haka Tours Ltd Terms and Conditions</Link>
              </i>
            </p>
          </div>
        </section>
      </Layout>
    );
  }
}

export default LightsCameraAction;
